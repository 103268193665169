﻿
@media(max-width:1300px) {
    .search-item-wrap {
        width: 100%;
        margin-top: 10px !important;
        max-width: 100%;
    }
}
@media(max-width:1200px) {
    .all-order-row{
        flex-direction: column;
        margin-bottom: 30px;
    }
   .all-order-inner-row {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
    }
    .order-r{
        width: 100%;
    }
  
    .order-r .total-r-label .t-label {
        color: #777;
        margin-bottom: 5px;
    }
    .menu-page-wrap .menu-right-penal .shoply-btn-wrap {
        display: block;
        width: 100%;
        justify-content: space-between;
        padding: 25px 15px 0px 10px;
        float: left;
    }
    .shoply-wrap  {
        .item-option-detail-wrap{
            float: left;
        }
    }
    .menu-page-wrap .menu-right-penal .shoply-btn-wrap .btn-inner-wrap {
 
        margin-bottom: 20px;
    }
    .extra-page-wrap .extras-footer-buttons-wrap {
        display: block;

        .extras-buttons-wrap {
            float: right;
            margin-top: 15px;
            clear: both;
        }
    }
    .menu-page-wrap .menu-right-penal .add-option-field-wrap {
        display: table;
        padding: 0px 0px;
        margin-bottom: 15px;
    }

    .menu-page-wrap .menu-right-penal .add-option-field-wrap div {
        width: 50%;
        display: table-cell;
        padding: 0px 5px;
        margin-right: 0px;
    }

    .menu-page-wrap .menu-right-penal .add-option-field-wrap .add-option-save-btn {
        margin: 0px 5px !important;
        justify-content: center;
        float: right;
    }

    .menu-page-wrap .menu-right-penal .add-item-btn-wrap {
        position: relative;
    }

    .menu-page-wrap .items-check-boxes-wrap .items-heading {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .menu-page-wrap .menu-right-penal .add-item-btn-wrap .add-cat-btn {
        position: absolute;
        right: 15px;
    }



    .searchCustomModifications{
        width:25% !important;
    }        
    // .hide-in-responsive {
    //     display: none;
    // }

    .modal-media-library-wrap .photo-library-wrap .photo-label {
        width: 230px;
    }
    .topping-page-wrap .extras-header-wrap {
        display: block;
        float: left;
        width: 100%;
        padding: 20px 10px 20px 10px;

        .extras-buttons-wrap {
            float: right;
            margin-top: 20px;
        }
    }
.topping-page-wrap .extras-buttons-wrap span {
        cursor: pointer;
        margin-left: 10px;
    }
    .menu-page-wrap .menu-left-penal{
        width: 260px;
    }
    .add-item-btn-wrap .items-check-boxes-wrap{
        width: 100%;
        padding-right: 105px;
    }
}

@media (max-width:992px){
    .navbar-brand{display:none!important;}
    a.core-logo {
        display: none;
    }
        
}

@media (max-width:1024px) {
    .page-wrapper {
        margin-left: 0px;
    }

    #generalSettingsDv {
        form .form-group input[type="text"] {
            width: 100%;
        }
    }

    #userConversation {
        .chat-main-box {
            .chat-left-aside {
                .user-image-chat-dv {
                    display: flex !important;
                    width: 100%;
                }
            }

            .chat-right-aside {
                width: 100%;
            }
        }

        #collapseExample {
            .moreDetails {
                width: 100%;
            }
        }
    }
}

@media (min-width: 1000px) {
    .page-wrapper, .footer {
        margin-left: 220px;
    }

    .hide-web-view {
        display: none;
    }
    .item-option-detail-wrap{
        float: left;
    }
    .menu-page-wrap .menu-right-penal .add-option-btn{
        margin-left: 15px;
        float: left;
    }
   
    .item-option-detail-wrap .item-option-detail-inner-wrap .item-option-topping-extras span {
        width: auto;
    }
    .item-row-wrap{
        margin-left: -60px;
    }
    .menu-page-wrap .menu-right-penal .item-main-row {

        padding-left: 60px;
    }
    p.item-row-wrap {
        margin-left: 0px;
        max-width: 800px;
    }
    
    .shoply-wrap{
        .item-main-row {
            padding-left: 0px!important;
        }
        .item-row-wrap {
            margin-left: 0px;
        }
    }
    
}
@media (max-width: 1100px){
    .delivery-area-page .accordian-open-wrap .delivery-area-row{
        width: 100%;

    }
}
@media (max-width: 1000px) {
    .new-table-set{
        .progres-bar-wrap {
            float: none;
            margin-bottom: 20px;
        }
    }
    .shoply-header .republish-btn {
        margin-top: 0px;
    }
    .add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row .area-modal-body-headng {
        margin-left: -25px;
        width: 360px;
    }
    
    


    .topping-list-wrap .topping-list-row{
        width: 50%;
    }
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 0px;
    }
    .topping-page-wrap .extras-header-wrap {
        padding: 20px;
    }
    .topping-page-wrap .extras-buttons-wrap .common-cat-icon-span {
        cursor: pointer;
        margin-left: 20px;
    }
    // .hide-in-responsive {
    //     display: none;
    // }
    #loginWrapper{
        .loginMainDV{
            width:100%;
        }
    }
}

@media (min-width: 992px) {
    .header-fixed .app-body {
        margin-top: 66px;
    }
    // .space-visible{margin-top: 123px!important;} 
    #MenuCoverPopup .modal-lg, #itemPhotoModal {
        max-width: 1100px;
    }
    .sidebar-fixed .sidebar {
        width: 220px;
    }
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 220px;
    }
}
@media (max-width: 991.98px){
    // .space-visible{margin-top: 123px!important;} 
    .space-visible  .sidebar {
        padding-bottom: 80px;
    }

    .app-body {
        margin-top: 65px;
    }
}
@media (min-width:800px) {
    .invoice-modal-height{
        .modal-content{
            height: 92vh;
        }
        .invoice-iframe{
            height: 100%;
        }
    }
    #orderWrapper .common-label-color, .pre-t-label {
        font-size: 14px;
    }

    .App-notifi-wrap {
        display: flex;
        width: 100%;
        .d-start {
            display: flex;
            flex-direction: column;
        }
    }
    .photo-library-wrap {
        max-height: 260px;
        overflow-y: auto;
        margin-bottom: 0px!important;
        overflow-x: hidden;
    }

  .add-single-item-wrap {
        max-height: 480px;
        overflow-y: auto;
    }
    .scroll-model-web{
        max-height: 490px;
overflow-y: auto;
    }
    .app-footer{    z-index: 1;}
    .sortable-wrap {
        counter-reset: section;
        overflow-y: auto;
        max-height: 300px;
    }
    
    .space-visible{ 
        .affix {
        display: block;
        .menu-left-penal {
            float: left;
            position: fixed;
            top: 120px;
            max-width: 265px;

        }
    }
    }
    .affix {
        display: block;
        .menu-left-penal {
            float: left;
            position: fixed;
            top: 70px;
            max-width: 265px;
        }
        .menu-right-penal {
            width: 100%;
            float: right;
            padding-left: 285px;
            border-left: none;
            border-top: none;
            .loader-menu-inner{    margin-left: -270px;}
        }
        .menu-left-cat-list{   
            // max-height: 380px;
             height: 57vh;
            overflow-y: auto;
            background: #fff;
            .menu-left-cat-list{    padding-bottom: 60px;}
        }
        .space-visible{
            .menu-left-cat-list{   
                 height: 56vh!important;
            }
        }

    }

    .modal-body {
        padding: 20px;
    }
    .padding-0 {
        padding: 0px;
    }
    .web-ponsive-cate{
        display: block;
        position: absolute;
        right: 0px;
        bottom: 30px;
        font-size: 22px;
        z-index: 2;
        width: auto;
        .list-item-d {
            margin: 0px 10px;
            cursor: pointer;
            i {
                font-size: 24px!important;
                text-shadow: 0px 2px 2px #000;
                color:#fff!important;
                /* box-shadow: 1px 1px 1px #000; */
            }
            span{
                display: none;
            }
        }
    }
    .res-ponsive-cate {
        display: none;
        position: absolute;
        right: 0px;
        bottom: 22px;
        font-size: 22px;
        z-index: 1;
        width: 100%;

        // span {
        //     cursor: pointer;
        //     margin: 0px 5px;
        //     float: left;
        //     text-align: center;
        // }

        .common-cat-icon-span {
            margin: 0px;
            display: inline-block;
            font-size: 12px;
            width: 100%;
        }

        .menu-left-list-buttons {
            float: right;
            margin: 0px;
        }
    }
//   .customer-table  {
//         .left-order-row, .right-order-row{
// div{
//     flex: none;
//     display: flex;
//     align-items: center;
//     flex-direction: row;
//     margin-bottom: 0px!important;
//     margin-right: 7px;
// }
//         }
//     }
.customer-table  {
    td,th{
        padding: 4px 10px!important;
    }
    .value-paid, .not-paid{
        font-size: 18px;
        width: 110px;
    }
    .Common-label-all-order, .t-label{
        margin-left: 7px;
        margin-right: 7px;
        font-size: 13px!important;
    }
    .order-total-r-wrap{
        flex-direction: row;
        white-space: nowrap;
    }
    .order-id-type-wrap div{
        display: flex;
        align-items: center;
    }
    .order-id{
        margin-bottom: 0px;
    }
    .dropup, .dropright, .dropdown, .dropleft{
        display: flex;
        align-items: center;
    }
    .m-b-10 {
        margin-bottom: 0px;
    }
    .t-price{
        margin-right: 7px;
        min-width: 70px;
    }
    .col-c-warp {
        width: 20%;
        display: flex;
        padding: 0px 7px;
    }
    .w-20{
        width: 20%;
    }
    .w-30{
        width: 30%;
    }
    .c-order-row {
        width: 100%;
        display: flex;
    }
}

    .value-paid, .not-paid{
        margin-top: 0px;
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 991.98px){

.sidebar {
    width: 220px;
}
}
.breadcrumb{
    
    display: none
}
@media (max-width:800px) {
    .devices-status-m-wrap .order-r{
        flex: none;
    }
    .devices-status-m-wrap .status-id-row {
        font-size: 16px;
    
    }
    .customer-table  {
        td,th{
            padding: 4px 10px!important;
        }
        .value-paid, .not-paid{
            font-size: 18px;
            width: 110px;
        }
        .Common-label-all-order, .t-label{
            margin-left: 7px;
            margin-right: 7px;
            font-size: 13px!important;
        }
    
     
        .order-id{
            margin-bottom: 0px;
        }
    
        .m-b-10 {
            margin-bottom: 0px;
        }
        .t-price{
            margin-right: 7px;
            min-width: 70px;
        }
        .col-c-warp {
            width: 50%;
            display: flex;
            padding: 7px 7px 7px 7px;
        }
    
        .c-order-row {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    }
    .card-new-title {
        padding: 0px 10px;
        margin-bottom: 15px;
    }
    .v-d-main-wrap{
        .v-d-wrap {
            display: flex;
            margin-right: 0px;
            align-items: flex-start;
            width: 50%;
            flex-direction: column;
            margin-bottom: 15px;
            
            span + span{
                font-size: 16px;
                margin: 0px;
            }
        }
       
    }
    .v-d-wrap:nth-last-child(2){
        margin-bottom: 0px!important;
    }
    .v-d-wrap:nth-last-child(1){
        margin-bottom: 0px!important;
    }
    .card-right-btns .excel-link {
        padding: 10px 5px;
    }
    .App-notifi-wrap{
        margin-bottom: 15px;
        float: left;
        .d-start {
       
            .u-heading {
                float: left;
    width: 45%;
            }
        }
    
    }


    .del-edit-wrap{
		.statusChangeLink span{
			display: none;
		}
        .slider-buttons{
            width: 60px;
        }
        .m-r-20{
            margin-right: 10px;
        }
	}
    .no-item-img{
        width: 100%;
    }
    .slide-view-wrap {
        flex-direction: column;
        padding: 0px 0px 0px 0px;
        margin-bottom: 35px !important;
        .card-body{
            padding: 15px 15px 15px 0px;
        }
        img {
            width: 100%;
        }
        h2{
            font-size: 20px;
        }
        p{
            margin-bottom: 15px;
        }
        .btn{
            padding: 4px 7px!important;
            font-size: 12px;
        }
        .slider-buttons{
            justify-content: center;
    width: 100%;
            div{
                display: flex;
                width: 100%;
                justify-content: center;
                .statusChangeLink{
                    flex: 1;
                    text-align: center;
                }
            
            }
            
        }
        .url-wrap{
            flex-direction: column;
            .item-url{
                margin-left: 0px;
    margin-top: 10px;
            }
        }
    }
    
    
    .topbar{
        .navbar {
            display: flex;
            flex-wrap: wrap;
            padding: 0.5rem 6px;
            .navbar-nav{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
               .nav-item{
                    margin-bottom: 10px;
                    .nav-link{
                        font-size: 12px;
                        margin: 0px 5px;
                        padding: 10px 5px;
                    }
                    .fa {
                        font-size: 16px!important;
                    }
                }
            }
        }

    }
    .invoice-iframe-body{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .invoice-iframe{
	height: 98%;
    }
    #spTotalDevices {
        margin-right: 0px;
    }
    .mobile-wrap {
        margin: 0px 20px 0px 0px;
        display: flex;
        align-items: center;
    }
    .move-modal {
        max-height: 100%;
        overflow-y: auto;
    }
    .center-rider-wrap{
        margin-top: 0px;
    }
    .alert-info{
        margin-bottom: 10px;
    }
    .rider-modal-wrap{
        padding: 0px 0px 70px 0px !important;
    }
    .rider-wrap {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        width: 100%;
    }
    .rider-wrap .rider-left {
        padding: 20px 15px;
        width: 100%;
    }
    .rider-wrap .rider-detail {
        flex-direction: column;
        display: flex;
        width: 100%;
        margin-bottom: 20px;
    }
    .modal-dialog {
        max-width: 800px!important;
    }
    .order-d-res-wrap{
        padding-left: 0px!important;
        padding-right: 0px!important;
        display: flex;
flex-direction: column;
        iframe{
            height: 100%!important;
            flex: 1;
        }
    }
    .res-name-new-wrp {
        width: 100%!important;
        padding: 5px;
    }
    .modal-media-library-wrap .photo-library-wrap{
        max-height: 100%!important;
    }
    .progres-bar-wrap {
        margin-bottom: 30px;
        margin-top: 20px;
        margin-right: 5px;
    }
// .scroll-media{
//     padding-top: 110px!important;
// }
    .order-total-r-wrap {
        display: flex;
        flex-direction: column;
        color: #000;
        align-items: flex-start;
        text-align: center !important;
    }
    #tblOrders   th, #tblOrders  td{
        padding: 0px!important;
    }
    .all-order-row {
        display: flex;
        padding: 0px 0px 10px 0px;}
        
.order-row-flex-none {
    padding: 10px 5px 0px 5px;
}
    .all-order-drop-down{
        flex-direction: column;
        align-items: flex-start;
    }
    //.order-r{margin: 15px 0px 0px 0px;}
    .order-r .total-r-label .t-label {
        color: #777;
        margin-bottom: 2px;
        font-size: 12px;
    }
    .order-r .total-r-label .t-label + span {
        font-size: 16px;
        margin-left: 0px;
        font-weight: bold;
    }
    .order-r {
        width: 100%;
        flex-wrap: wrap;
        padding: 10px;
        word-break: break-word;
        .total-r-label {
            align-items: flex-start;
            justify-content: flex-start;
            flex: none;
            width:47%;
            margin-right: 0px;
            margin-top: 10px;
            padding-right: 0px;
        }
     
    }
    .order-drop-dwon-set {
        width: 100%;
        justify-content: space-between;
        select{
            width: 170px;
        }
    }
    #tblOrders_wrapper .dataTables_filter {
        max-width: 250px;
    }
    .orderlink-wraper {
    display: block;
}
.left-order-row, .right-order-row {
    display: flex;
    padding: 10px 5px 0px 5px;
    align-items: flex-start;
    flex: none;
    justify-content: space-between;
    width: 100%;
}
.orderlink-wraper .order-date-dropdown {
    width: 100%;
    margin: 10px 0px 15px 0px;
}
    .iframe-all-orders iframe{
        width: 100%!important;
    border: none!important;
    height: 100vh!important;
    padding-bottom: 82px!important;
    padding-top: 30px;
    }
    .iframe-all-orders{
        padding: 0px 0px!important;
     
    }
    .headerInfoDiv button, .headerInfoDiv .button{
        line-height: 16px;
        span{
            font-size: 12px;
        }
    }
    .live-order-btn{
        margin-left: 0px;
    }
    .imp-lodar {
        position: absolute;
        right: 15px;
        top: 10px;
    }
    #orderWrapper .time-label-text, #orderWrapper .user-order-count, #orderWrapper .Pre-order-label{
        font-size: 11px;
    }
    .col-md-4.btnAddRow {
		padding: 0.775rem 1.75rem 0.875rem 0.45rem;
    }
    .row.location-field-wrap .col-md-4 {
        width: 33.33%;
        padding: 0px 5px;
    }
    .dataTables_filter {
        width: 100%;
    }
    // ul.react-tabs__tab-list {
    //     position: fixed;
    //     width: 100%;
    //     top: 70px;
    // }
    .modal-dialog.modal-lg {
        max-width: 100%!important;
    }
    #loginWrapper .loginMainDV {
        width: 60% !important;
        margin: 0 auto;
    }
    div#loginWrapper:before{
        display:none;
    }
    .login-rightimg.col-md-4 {
        display: none;
    }
    .app-header .nav-item .nav-link{
        margin-right:0px;
    }

//     .republish-btn{
//         height: 35px;
//         width: 37px!important;
//             margin-top: 0px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
      
//             width: 160px !important;
//             line-height: 15px;
//             font-size: 14px;
//         i{margin: 0px;font-size: 18px!important;padding-right: 8px;
//         }
// span + span{
// display: block;
// font-size: 14px;
// }
//     }
    // .headerInfoDiv{
    //     .btn-success {
    //         height: 35px;
    //         width: 160px!important;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
        
    //         i{
    //             margin: 0px!important;
    //             font-size: 18px!important;
    //             padding-right: 8px;
    //         }
    //         .statusSpan{display: block;}
    //     }
    // }
    .Vouchers-wrap .voucher-detail-row {
        display: block;
        margin: 0px -30px;
    }
    .voucher-left-section{margin-bottom: 15px;}
    .voucher-left-section, .voucher-right-section{
        justify-content: space-between;
    }
    .voucher-detail {
        flex:none!important;
    }
    .voucher-detail + .voucher-detail{
        width: 134px;
    }
    .Vouchers-wrap .voucher-title-row {
      justify-content: space-between;
    }

    .Vouchers-wrap .voucher-title-row .voucher-offer-wrap {
        min-width: auto;
    }
    .vouchers-main-wrap .table th{
        display: none;
    } 
    .vouchers-main-wrap .table{ width: 100%!important;}
    .vouchers-main-wrap .table th, .vouchers-main-wrap .table td {
        padding: 8px 0px!important;
    }
    .Vouchers-wrap .voucher-common-data {
        font-size: 13px;
        font-weight: bold;
    }
    .Vouchers-wrap .voucher-title-row .voucher-offer-wrap .voucher-heading {
        font-size: 16px;
    }
    .Vouchers-wrap .voucher-title-row .voucher-code{
        font-size: 16px;
    }
    .Vouchers-wrap .voucher-title-row .voucher-code-wrap span {

        font-size: 12px;
    }
    /*All orders page css starts here*/

    .left-order-row {
        margin-bottom: 10px;
    }
    .left-order-row div + div, .right-order-row div + div{

        text-align: left;
    }
    .pre-t-label {
        font-size: 11px;
    }
     /*All orders page css Ends here*/



    .all-order-res-scroll {
        max-width: 800px;
        overflow-x: auto;
    }

    .modal-header{    z-index: 99999999;}
    .sortable-new-wrap {
        margin-bottom: 20px;
    }
    .space-visible {
        margin-top: 137px!important;
    }
    .time-wraper .rc-time-picker-panel-input-wrap {
        width: auto;
    }
    .rc-time-picker-panel{width: auto;}
    .time-wraper .btn {
        width: 57px;
    }
    .time-picker-main-wraper .checkBoxDV {
        margin-bottom: 20px;
        display: block;
    }
    .time-picker-main-wraper .dayLabel {
        margin-bottom: 7px;
    }
    
    .menu-alert-wrap{    padding-left: 15px;
        .right-alert{.btn{
            white-space: nowrap;
        }}
    }
   // .res-all-t-list{}
   .topping-subheader-page{   
    padding: 10px 15px 0px 15px;
    z-index: 1;
    width: 100%;font-size: 22px!important;}
    .topping-subheader-fixed {
        background: #fff;
        padding: 10px 15px 15px 15px;
        margin: 0px!important;
        z-index: 1;
        position: fixed;
        width: 100%;
        top: 66px;
        .topping-list-search {
            clear: both;
            margin: 0px;
            width: 100%;
            max-width: 100%;
            margin-top: 5px;
        }
    }

  .topping-list-search {
        clear: both;
        margin: 0px;
        width: 100%;
        max-width: 100%;
        margin-top: 5px;
    }


    .res-m-15{
        margin-right: 15px;
    }
    .admin-restaurant-wrap .admin-restaurant-row .image-wrap {
        padding-right: 15px;
        margin-left: 0px;
        img{    width: 45px;	height: 45px;}
    }
    .admin-restaurant-wrap{
        .my-order-new-row{
            display: flex!important;
        }
        .left-row {
            padding-right: 0px;
        }
        .right-dropdown{  
            .statusChangeLink i{    margin-right: 5px;}
        }
    }
    .admin-restaurant-wrap .rest-name-heading {
        font-size: 16px;
    } 

    .sidebar-show{overflow: hidden;
        .ps{overflow-y: auto !important;}
        .ps__rail-y {
            display: none!important;
        }
        .sidebar .nav{    padding-bottom: 80px;}
    }
    .invoice-main-wrap{
        .card-body {
            padding:15px;
        }    
        table th, .table td {
            padding: 10px 0px;
        }    
    }  
    .invoice-wrap, .admin-restaurant-wrap{
        .link-t-c {
            font-size: 12px;
        }
        .my-order-wrap { padding: 20px 15px; }
           
            .my-order-id-name-wrap {
                font-size: 12px;
            }
            .my-order-id-label {
                font-size: 20px;
                font-weight: bold;
                margin-right: 5px;
            }
            .my-order-new-row {
                margin-bottom: 0px;
                display: table;
    width: 100%;
    position: relative;
            }
            .left-row, .right-row {
           
                margin-bottom: 15px;
                display: flex;
                width: 100%;
                flex: none;
            }
            .my-order-label {
                font-size: 13px!important;
            }
            .my-order-label-sub, .forward-id-label {
                font-size: 13px!important;
            }  
       
    }


    .card-body-res{
            
        padding: 20px 10px;
            }
            .card-body {
                margin: 0px 10px;
            }

    #userDataWraper	{
        .card-body{
            
    padding: 20px 10px;
        }
        .vatar-name-wraper {
            .sb-avatar{margin-right: 15px;
                }
        }
        .user-left-col {
            font-size: 12px;
            .u-heading{
                font-size: 16px;
        
                span{
                    font-size: 14px;
               
             
                }
            }
            span{   float: left;
              
                
                color: #777;
               
            i{    margin-right: 7px;
               }
            }
        }

    }


    .user-avatar-web{
        display: none;
    }
    .user-avatar-res{  display: block;} 
    .show-web{display: none!important;}
    .user-data-action-btn-res{
        .show-res{display: block;    text-align: right;}
        .btn-secondary{    background: none!important;
            border: none;
            padding: 0px;
        span{
            display: block;
            font-size: 20px;
            color: #000;
            margin-bottom: -5px;
        }
        span + span{
            color:#333;
            font-size: 12px; 
        }
        }
        .dropdown-toggle::after{display:none;}
        .dropdown-toggle:focus {
            box-shadow: none!important;
        }
        .statusChangeLink{    display: flex;
            margin-bottom: 5px!important;}

            .dropdown-menu.show {
                display: block;
                background: #fff;
                color: #333;
                position: absolute;
                right: 10px;
                top: 55px;
                box-shadow: 1px 1px 5px #aaa;
                text-align: left;
                width: 180px;

                
            }
            .statusChangeLink {
                display: flex;
                margin-bottom: 0px !important;
                display: flex;
                padding: 13px 15px;
                text-align: left;
                border-bottom: 1px solid #d2d2d2;
                cursor: pointer;
                align-items: center;
                color: #333!important;
                margin-right: 0px;
                font-size: 13px;
                .fa{
                    margin-right: 5px;
                    font-size: 16px;
                }
                &:hover{background: #f2f2f2;}
                a{ color: #333!important;}
              
            }
            .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{

                background-color: #fff;
            }
	}
    .add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row{
        display: block;
    }
    .add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row .area-modal-body-headng {
        margin-bottom: 10px;
        margin-left: 0px;
        width: 100%;
    }
    .area-modal {
        padding-left: 10px!important;
        padding-right: 10px!important;
        padding-top: 75px!important;
    }
    .menu-page-wrap .menu-right-penal .add-item-btn-wrap .add-cat-btn {
        right: 0px;
    }
    .topping-list-wrap .topping-list-row {
        width: 100%;
        padding: 0px 0px;
    }
    .menu-page-wrap .items-check-boxes-wrap .items-heading{font-size: 16px;}
    .topping-subheader-page .topping-heading-page {
        margin-bottom: 10px;
    }
    ul.navbar-nav {
       // display: none;
    }        
    .menu-page-wrap .menu-right-penal {
        border: none;
    }
    .menu-page-wrap .select-cat-btn-res span {
        font-size: 18px ;
        font-weight: bold;
    }
    .change-cat-modal {

        font-size: 14px !important;
    
    }
    .menu-page-wrap .common-heading, .menu-page-wrap .extas-common-heading {
        font-size: 16px !important;
        margin-bottom: 0px;
    }
    .modal-body {
        padding: 20px;
    }
    .container-fluid {
        padding: 0 0px !important;
    }
    #leftcategory{display:none;}
    #reviewWrapper .commentBoxMainDV .commentBox{
        padding: 15px;
    }
    // .add-cat-btn{
    //     .m-r-5 {
    //         margin-right: 0px;
    //     }
    // }
    .extra-page-wrap {
        .menu-right-penal {
        float: left;
        width: 100%;
        border: none;
    }
    .extras-header-wrap {
        display: block;
        padding: 0px 0px 20px 0px;
        .extras-header-left {
            margin-bottom: 10px;
            .extas-common-heading {
                font-size: 16px !important;
            }
        }
        .extras-buttons-wrap {
            text-align: right;
        }
    }
    .topTitleDiv{
        flex-direction: column;
        .dropdownDiv{
            width:100%;
            padding-left: 15px;
            margin-top: 15px;
            margin-bottom: 5px;
        }
    }
}
.extras-item-wrap {
    padding: 0px 0px 0px 0px!important;
}

.extras-item-wrap .extras-item-detail-wrap .extras-row-wrap .extras-row {
    padding-right: 60px;
    max-width: 100%;
}

.extras-item-wrap .extras-maxium-item-select {
    display: block!important;
}

.extras-item-wrap .extra-resp-maxium-wrap {
    display: flex!important;
    justify-content: space-between;
}

.extas-common-heading {
    margin-bottom: 10px;
    display: block;
}

.extras-item-wrap .extras-maxium-item-select .extras-update-label {
    margin: 0px 20px 0px 0px!important;
}
.extra-page-wrap .extras-footer-buttons-wrap {
    padding: 0px 0px 20px 0px;
}
    .modal-media-library-wrap .photo-library-wrap .photo-label div div {
        margin-top: 20px;
        font-size: 12px;
        font-weight: normal;
    }

    .menu-page-wrap .menu-right-penal .add-item-btn-wrap {
        position: relative;
        padding-right: 0px !important;
    }

    .search-item-wrap {
        margin-top: 15px !important;
        width: 100% !important;
    }
    .searchCustomModifications{
        width:250px!important;
    } 
    /*bootstrap responsive common css starts here*/
    .modal-header {
        padding: 10px 15px;
        border-bottom: 1px solid #e5e5e5;
        min-height: 16.42857143px;
        position: fixed;
        width: 100%;
        background: #fff;
        z-index: 111111;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .modal {
        padding: 0px !important;
    }

    .modal-dialog {
        margin: 0px!important;
        position: relative;
        width: auto;
        margin: 0px;
        max-width: 100%;
        height: 100%;
    }
    
    .modal-content {
        position: relative;
        background-color: #ffffff;
        border: 1px solid #999999;
        border: 0px;
        border-radius: 0px;
        -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
        box-shadow: none;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        outline: 0;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .modal-body {
        width: 100%;
        padding-bottom: 65px;
        padding-top: 80px;
    }

    .modal-footer {
        padding: 15px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        position: fixed !important;
        bottom: 0px;
        width: 100%;
        background: #fff;
        z-index: 9999;
        left: 0px;
    }
    .padding-20 {
        padding: 0px;
    }
    /*bootstrap responsive common css ends here*/
    .menu-page-wrap {
        display: block;
       // padding: 15px;
    }

    

    .usernameDrop {
        display: none !important;
    }

    .card-title {
        font-size: 20px;
      //  margin: 0px;
    }
    .headingComm{
        margin: 0px;
    }
    .formBorder {
        border: 1px solid #d2d2d2!important;
    }

    .menu-page-wrap {
        .add-item-btn-wrap, .item-row-wrap, .item-option-detail-wrap {
            padding-left: 0px !important;
            padding-right: 0px !important
        }

        .menu-right-penal {
            .add-option-field-wrap {
                display: table;
                padding: 0px 0px;
                margin-bottom: 15px;

                div {
                    width: 50%;
                    display: table-cell;
                    padding: 0px 5px;
                    margin-right: 0px;
                }

                .add-option-save-btn {
                    margin: 0px 5px !important;
                    justify-content: center;
                    float: right;
                }
            }
        }
    }

    .cat-res-custom-dropdown {
        .btn-group {
            width: 100%;
            float: left;
            margin: 15px 0px 15px 0px;
        }

        .dropdown-toggle {
            width: 100%;
            float: left;
            padding: 10px;
            text-align: left;
            font-size: 16px;
            padding-right: 25px;
        }

        .dropdown-toggle::after {
            position: absolute;
            right: 10px;
            top: 20px;
        }

        .dropdown-menu {
            width: 100%;
        }
    }

    .container-pad-0 {
        padding: 0px !important;
    }

    .responsive-cate-left {
        display: block;
        margin-top: 15px;
    }

    .menu-left-penal {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .select-cat-btn-res {
        width: 100%;
        font-size: 16px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #d2d2d2;
        padding: 10px 15px;
        border-radius: 4px;
        background: #f2f2f2;
        cursor: pointer;

        span {
            font-size: 22px;
            color: #333;
            font-weight: 500;
        }

        .change-cat-modal {
            position: relative;
            padding-right: 20px;
            cursor: pointer;
            font-size: 14px;
            color: #2196f3;
            span{display: block;
                color: #2196f3;
                font-size: 14px;}    
        }

        .change-cat-modal:after {
            position: absolute;
            top: 25px;
            right: 4px;
            margin-top: -17px;
            width: 0;
            height: 0;
            border-top: 5px solid;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            color: #999;
            content: "";
            pointer-events: none;
        }
    }

    .restaurant-admin-detail {
        .profile-admin-inner-detail {
            padding-top: 10px;
            width: 100%;
            display: table;

            .profile-detail {
                display: inline-block;
                padding-right: 0px;
                vertical-align: top;
                width: 100%;
                text-align: left;

                .res-profile-photo-wrap {
                    position: relative;
                    padding-left: 0px;

                    img {
                        left: 0px;
                        margin: 0px auto;
                        display: block;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .opening-hour-wrap {
            display: inline-block;
            width: 100%;

            .restaurant-info-working-hours {
                float: none;
                width: 100%;
                margin: 0px 0px;
                padding: 10px;
            }
        }
    }

    .menu-category-image-wraper {
        min-height: 80px !important;

        .menu-heading-desc-wrapper {
            font-size: 20px !important;
            line-height: 26px;
            padding-right: 15px;
            padding-right: 70px!important;
            margin-bottom: 0px;
        }

        .res-ponsive-cate {
            position: absolute;
            bottom: 5px;
            left: auto;
            display: block;
            right: 0px;
            z-index: 1;
        }

        

        .menu-right-update-image-wrap {
            display: none !important;
        }
    }

    .menu-page-wrap .menu-right-penal .add-option-btn {
        margin: 10px 0px 10px 0px;
    }
    /*photo change modal css responsive*/
    .customtab2 {
        margin-top: 10px;
    }

    .modal-media-library-wrap {
        .select-media-name, .search-photo-wrap {
            max-width: 100%;
            width: 100%;
            margin-bottom: 15px;

            .control-label {
                width: 100px;
            }

            .control-label + span {
                flex: 1;
            }
        }
    }


    
    .topping-page-wrap .extras-header-wrap {
        padding: 0px;
    }

    .topping-page-wrap .extras-header-wrap .extras-buttons-wrap {
        float: right;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: right;

        .add-cat-btn {
            float: left;
        }
    }
    .topping-page-wrap .extras-buttons-wrap a {
        margin-top: 10px;
        margin-bottom: 15px;
        display: inline-block;
    }
    .clearfix-res-wrap {
        clear: both;
    }
        .topping-page-wrap .Topping-item-wrap {
        padding: 0px;
    }
.topping-page-wrap .Topping-item-wrap .topping-row {
        max-width: 100%;
    }
    .topping-page-wrap .Topping-item-wrap .topping-row .topping-item-list {
        max-width: 100%;
    }
    .disp-name{
        display:none !important;
    }
    .headerMain.shoply-header .m-r-10{
        margin-right: 5px;
        }
    .new-table-set .dataTables_filter {
            margin-top: 30px;
        }
        .new-res-data-table{
            #tblOrders_wrapper .dataTables_filter{
                max-width: 100%;
            }
            table { 
                width: 100%; 
          }
        
          /* Force table to not be like tables anymore */
          table, thead, tbody, th, td, tr { 
              display: block; 
          }
          
          /* Hide table headers (but not display: none;, for accessibility) */
          thead tr { 
              position: absolute;
              top: -9999px;
              left: -9999px;
          }
          
          tr { border: 1px solid #ccc;
            border-top: none;
            font-size: 13px;
        
        }
          
            #tblOrders  td { 
                border: none;
                position: relative;
                padding: 7px 10px !important;
                display: flex;
                align-items: center;
                padding-left: 110px !important;
                word-break: break-word;
          }
        
          td:before { 
            position: absolute;
            /* top: 10px; */
            left: 10px;
            /* width: 130px; */
            padding-right: 10px;
            white-space: nowrap;
            content: attr(data-column);
            font-weight: bold;
            font-size: 12px;
          }
        
                  }
}

@media (max-width:767px) {
    .order-status-main-wrap {
        flex-wrap: wrap;
    }
    .order-list-col-1 {
        width: 100%;
        padding: 0px 20px;
    }
    .order-list-col-1 li {
        display: inline-flex;
        width: 100%;
        align-items: center;
        margin-bottom: 1rem;
        margin-right: 0px;   
    max-width: 100%;
    }
 
    .all-order-drop-down{
		.react-datepicker__triangle {
			display: none!important;
		}
			
		.react-datepicker-popper {
			z-index: 1;
			transform: none!important;
			top: 32px!important;
		}
	}
    .res-date-wrap {
        width: 100%;
        margin-top: 1rem;
    }
  
    .react-datepicker-wrapper {
        width: 100%;
    
    }
    .child-th-wrap{
        margin-top: 15px;
    }
    .ds-nametext {
		display: none;
	}
    .forgotlogotext {
        font-size: 17px;
        margin-bottom: 40px;
    }
    .u-pro {
        display: none;
    }
    .navbar-header {
        display: none;
    }
    .page-wrapper {
        margin-left: 0px;
        .enterprise-status-bar {
            width: 100%;
        }
    }
    .chat-main-box {
        .chat-left-aside {
            left: 0px;
            width: 100%;
        }
        .chat-right-aside {
            width: 100%;
        }
    }
    #reviewWrapper{
        .ratingMainDV{
           .ratingSubDVTwo {
               .ratingGroupColumn{
                   justify-content: center;
               }
               .ratingLabel{
                   text-align: center;
               }
           }
        } 
        .commentBoxMainDV{
           flex-wrap: wrap;
           .commentBox{
               width: 100%;
           }
        }
       }

       .mapAddZoneMainDiv{
           flex-direction: column-reverse;
       }

       .mapDiv{
           width: 100%;
        
       }
       .addZoneDiv{
           min-height: auto;
           width:100%;
       }
       .responsiveButtonInfo{
           display:block;   
       }

       #settingsWrapper{
        .settingsFlexDv{
            justify-content: center;
        }
    }
   
}

@media (max-width:600px) {
    /*Delivery and Working Hours Css*/
    .time-input {
        width: 90% !important;
    }

    .colHour {
        margin-bottom: 10px;
    }

    .colHour span {
        width: 50%;
    }

    .timepicker {
        display: flex;
    }
    /*End Delivery and Working Hours Css*/
}

@media (max-width:530px) {
    .input-field-time{    position: relative;
        width: 47%;}
        .time-picker-main-wraper{
        .applyAllDv{
            display: block;
        }
        .applyAllLabel{
            width: 100%;
            justify-content: flex-end;
            margin-bottom: 10px;
        }
        .dayLabel + div, .checkBoxAdvDV + div{    justify-content: space-between;}
        .input-field-time{margin-right: 0px;}
    }
    #reviewWrapper{
     .ratingMainDV{
         .ratingSubDVOne{
             margin-bottom:20px;
         }
        .ratingSubDVTwo {
            .ratingGroupRow{
                flex-direction: column;
                margin-bottom: 0px !important;
                .ratingGroupColumn{
                    margin-bottom: 15px;
                }
            }
        }
     } 
    }
    .time-picker-main-wraper{
        .tab-pane{
            display:flex;
            justify-content: center;
            padding-left: 8px;
            padding-right: 0px;

        }
        .checkBoxDV{
            display: block;
        }	
    
        .timePickerDiv{
            margin-right: 10px;
        }
    }
}
// @media (min-width: 576px) {
//     .modal-dialog {
//         max-width: 880px;
//         margin: 1.75rem auto;
//     }
// }
@media (max-width: 575.98px){

.sidebar-show .main::before, .aside-menu-show .main::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1018;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.7);
    -webkit-animation: opacity 0.25s;
    animation: opacity 0.25s;
}
}
@media (min-width: 576px){
    .modal-dialog {
        max-width: 700px;
    }
    .modal-dialog.modal-lg {
        max-width: 80%;
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (max-width:480px) {
    .form-set-new-notification .order-r .total-r-label {
        width: 50%;
        margin-bottom: 15px;
      
    }
 
    .form-set-new-notification .control-label {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .form-set-new-notification {
        .form-group{
            width: 100%;
        }
        .flex-type-wrap {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
    }
    .form-set-new-notification-col-2{
        .flex-type-wrap {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
        .control-label{
            margin-bottom: 0.5rem;
        }
    }
    .flex-md-row.d-flex.align-items-center.mr-2 .react-datepicker-popper {
        z-index: 1;
        margin-left: -60px;
    }
    .devices-status-m-wrap .d-t-label{
        width: 100%;
        margin-left: 0px;
        line-height: initial;
        margin-bottom: 10px;
    }
    .p-l-r-0{
        padding: 0px 0px!important;
    }
    .all-order-drop-down.card-new-title.p-l-r-0.mb-0 {
        width: 100%;
        margin-left: 0px;
        margin: 10px 0px 0px 0px;
    }
    .react-datepicker__triangle {
        display: none;
    }
    .group-voucher .card-body-res .btn-setting{
        display: flex!important;
        justify-content: space-between!important;
        align-items: flex-start!important;  
        flex-direction: column;
        .card-right-btns{
            margin-top: 10px;
            margin-left: auto;
        }
    }
    .btn-primary-plus{
        height: auto;
    }
   .App-notifi-wrap{
    .d-start{
        font-size: 13px;
    }
    .badge {
        font-size: 13px;
    }
}
    .Notification-restaurant-wrap .rest-name-heading{
        font-size: 16px;
    }
    #userDataWraper .user-left-col .u-heading span{
        margin-right: 10px;
    }
    #userDataWraper tr td + td{
        padding-left: 10px;
    }
    #userDataWraper .user-d-wrap span {
        margin-right: 20px;
    }
    
    
    .sortable-nav{
		margin-left: 0px;
		
	}
    .navigation-item-row {
		.navigation-item {
			max-width: 100%;
		}
	}
    .react-tabs .affix{
        left: 0px;
    width: 100%;
    }
    #CampaignDataWraper .react-tabs__tab {
        padding: 10px 15px !important;
        font-size: 14px;
    }
    .search-theme-field-wrap .form-group {
     
        flex: 1;
    }
    .theme-list-b{
        flex-basis: 100%;
    }
    .theme-heading-wrap{
        .card-title {
            font-size: 16px;
            font-weight: bold;
        }
    }
    .theme-heading-wrap + .react-tabs .react-tabs__tab{
width: 50%;
    }
    .theme-group-wrap{
        .form-group.mb-3 {
            margin-right: 0px;
            width: 100%;
        }
        .relative {
            max-width: 100%;
        }
      
    }
  
    .common-theme-wrap{
        .m-l-10{margin: 0px;}
    }
    .formPadding {
        padding: 0px;
    }
    .total-device-wrap{
        padding: 15px 10px;
        font-size: 14px;
    }
    .left-order-row div, .right-order-row div {
        padding: 0px 7px 0px 0px;
    }
    .new-table-set .dataTables_filter {
        margin-top: 12px;
        max-width: 100%!important;
    }
   
    .progres-bar-wrap {
        margin-bottom: 10px;
        margin-top: 5px;
        margin-right: 5px;
        /* width: 100%; */
        justify-content: center;
    }
    .count-down-wrap {
        padding: 2px 5px 3px 7px;
        border-radius: 4px;
        position: relative;
        width: 150px;
    }
    .count-down-text-wrap {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
    }
    .count-down-text-wrap .count-down-text + span {
        font-size: 11px;
    }
    .countdown {
        border: 1px solid #ed0000;
        border-radius: 2px;
        width: 25px;
        position: absolute;
        top: 8px;
        right: 35px;
        height: 25px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ed0000;
        box-shadow: 0px 0px 3px #aaa;
    }
    .countdown.stop-btn-order {
        right: 5px;
    }
    .h-res{display: none;}
    .copy-res-name-wrap{
        max-height: 100%;
    }
    .copy-res-name-wrap h3 {
        width: 100%;
    }
    .res-search-photo-wrap {
        width: 100%;
    }
    .live-order-btn {
        height: 35px;
        font-size: 14px !important;
        line-height: 13px;
        .heartbit {
            top: -10px;
            right: -1px;
        }
     
    }
  

    .heartbit{
        top: -9px;
        right: -2px;
        height: 35px;
        width: 35px;
    }
  
    .headerInfoDiv{
        .m-r-15 {
            margin-right: 8px;
        }
        button, .button{
            height: 35px;
            font-size: 14px !important;
            line-height: 13px;
            span + span, .statusSpan{
                font-size: 12px;
            }
        }
    }
    .headerInfoDiv .m-l-15 {
        margin-left: 0px;
    }
    .republish-btn{
        i{margin: 0px;}
        .heartbit{
            right: -9px;
        }
        .heartbit-wrap + span{
            display: none;
        }
    }
    // .react-tabs__tab-list{
    //     margin: 0 -20px 0px -20px!important;
    // }
    .react-tabs__tab {
        padding: 10px 8px !important;
        font-size: 12px;
    }
    .react-tabs__tab i {
        font-size: 14px;
        margin-right: 5px;
    }
    .navbar-nav .header-avatar{
        width:30px !important;
        height:30px !important;

    }
    .app-header .nav-item{
        min-width: 35px;
    }
    .forgotlogotext {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .forgotpswdinner label.control-label {
        font-size: 12px;
    }
    .codeblow {
        margin: 20px 0;
        font-size: 12px;
    }
  

    #loginWrapper .loginMainDV {
        width: 90% !important;
        margin: 0 auto;
    }
    .res-logo-wrap {
        width: 100%;
    }
    .menu-page-wrap .menu-right-penal .item-row-wrap .menu-right-list-buttons {
        float: right;
        margin-top: 0px;
        text-align: right;
        flex: none;
    }
    .menu-page-wrap .menu-right-penal .item-row-wrap .menu-right-list-buttons span i {
        padding: 6px 0px 5px 12px;
        cursor: pointer;
    }
    .item-option-detail-wrap{
        align-items: flex-start;
    }
    .menu-page-wrap .menu-right-penal .shoply-btn-wrap {
        padding: 20px 0px 0px 0px;
  
    }
    .menu-page-wrap .menu-right-penal .shoply-btn-wrap .btn-inner-wrap{
        flex-wrap: wrap;
    }
    .shoply-btn-wrap{
        .items-check-boxes, .menu-sort-link, .btn-secondary {
            margin-bottom: 15px;
        }
    }
    .menu-page-wrap .menu-right-penal .shoply-btn-wrap .btn-inner-wrap {
        margin-bottom: 0px;
    }
    .mapDiv .zone-map-wrap{
        padding: 20px 10px;
        .new-btn-update{
            margin-left: 7px;
        }
    }
    main.main {
      //  margin-top: 0px;
    }
    .menu-ch{
        display: block!important;
        .modal-form-group {
           margin-right: 25px;
       }
   }
    .hide-res-480{display: none;}
    .box-campaign-wrap{
        margin-bottom: 0px;
    }
    .input-color-row{

        input{    font-size: 11px;
            padding-left: 5px;}
    }
    .orderlink-wraper{
        text-align: center;
    }
    .button-link{
        padding: 5px 0px;
        display: inline-block;
        text-decoration: none;
        font-size: 14px;
    }


    .orderlink-wraper ul li {
        display: inline-block;
        margin: 0px 15px;
    }
    .orderlink-wraper {
        margin: 28px 0px 20px 0px;
    }
    .show-check{font-size: 30px;}
    .login-title-wrap{
        font-size: 14px;
        img{
            width: 160px;
        }
    }
    .login-title {
        font-size: 18px;
    }
    .title-sperator{font-size: 16px;}
    .media-wraper .res-search-photo-wrap .box-wrap {
        width: 100%;
    }
    .search-add-delvery{
        width:100%;
    }
    .add-deliver-modal-wrap {

        .form-check {
            padding-left: 0px;
        }
    }
    .add-single-item-wrap .item-single-checked .input-group {
        right: 0px;
    }
    .new-cat-res-set{
        display: block;
        margin-left: 10px !important;
   
    }
    .back-page-link-wrap{
        display: block;
        .back-page-link-wrap a {
            float: right;
            margin-bottom: 15px;
        }
    }
    .accordian-c-t-d-wrap span {
        font-size: 12px;
        margin-left: 10px;
    }
    .navbar-nav{
        .header-avatar {
            display: block !important;
        }
    }
    .menu-alert-wrap .left-alert {
        font-size: 14px;
        padding-right: 20px;
        font-weight: normal;
        font-style: italic;
        display: flex;
        text-align: left;
        align-items: center;
    }
    .space-visible {
        margin-top: 122px;
    }
    .field-gen-wrp{display: block;}
    .rc-time-picker-panel {
        z-index: 1070;
        width: 100%!important;
        position: fixed!important;
        box-sizing: border-box;
        left: 0px!important;
        bottom: 0px;
        top: auto!important;
    }
    .rc-time-picker-panel-inner {
        width: 100%;
    }
    .time-wraper .btn {
        right: 8%;
    }
    .orderlink-wraper ul li a {
        padding: 5px 0px;
        display: inline-block;
        text-decoration: none;
        font-size: 14px;
        width: 100%;
    }
    .res-status-wrap .res-status-row .res-status-title {
        font-size: 14px;
    }
    .delivery-d-heaing-row span {
        font-size: 12px;
    }
    .delivery-area-page .accordian-open-wrap .delivery-area-row .delivery-d-wrap .zone-ignore-label span {
        font-size: 13px;
    }
    .delivery-area-page .accordian-open-wrap .delivery-area-row .delivery-d-wrap{
        padding-bottom: 40px;
        padding-right: 0px;
    }
.delivery-area-page .accordian-open-wrap .delivery-area-row .delivery-d-wrap .zone-ignore-label {
    float: right;
    position: absolute;
    left: 0px;
    right:0px;
    margin-right: 10px;
    color: #777;
    top: 42px;
}
    .add-deliver-modal-wrap .area-modal-body-wrap{    padding-left: 0px;}
    .add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row .area-modal-detail-wrap .name-field input {
        padding: 20px 10px 5px 10px;
        padding-left: 20px;
    }
    .add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row .area-modal-detail-wrap .time-field input {
        padding-left: 12px;
        padding-right: 35px;
    
    }
    .add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row .area-modal-detail-wrap .name-field .icon-symbal {
        top: 25px;
        left: 11px;
        font-size: 12px;
    }
    .add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row .area-modal-detail-wrap .time-field  .icon-symbal{
        top: 25px;
        font-size: 12px;
        right: 5px;
        left: auto;
    }
    .add-deliver-modal-wrap .city-town-wrap .select-city{width: 100%;}
    .add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row .area-modal-detail-wrap{
        width: 100%;
        flex-flow: wrap;
        margin-bottom: 30px;
        padding-left: 25px;
        .name-field {        flex-basis: 80px;
            margin-bottom: 5px;
            flex: none;
            width: 82px;
            margin-right: 10px;
            span{font-size: 11px;}
        }
    }
    .add-deliver-modal-wrap .flex{
        input{
            min-width: 18px;
            margin-right: 10px;
        }
    }
    .add-deliver-modal-wrap .area-modal-body-wrap .modal-label-head{
        font-size: 14px;
        font-weight: 600;

    }
    .time-field + div{  
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-top: 5px;
  
        .flex{   
             margin-left: 0px!important;
            margin-top: 0px!important;}
    }

    .delivery-area-page{

        .select-city-search-wrap{
			display: block;
			align-items: center;
			margin-bottom: 20px;
	 .select-city {
			display: flex;
			width: 100%;
			align-items: center;
			margin-bottom: 15px;
			justify-content: space-between;
			select {
    width: 140px;
}
}
.Delivery-search {
    width: 100%;
    position: relative;
    display: block;
}

        }
		
		.accordian-c-t-wrp{
		.accordian-title-icon-wrap{
		    font-size: 14px;
            font-weight: bold;
			flex: 1;
		}
		.accordian-c-t-d-wrap{
		flex: 0;
		span{
		font-size: 12px;
    float: left;
    margin-left: 20px;
    text-align: center;
	i{    margin-right: 0px;
    font-size: 14px;
    color: #03A9F4;}
		}
		}
		
		}
		.accordian-open-wrap {
			.delivery-area-row {
			    width: 100%;
    padding: 0px;
				.delivery-title-warp{
					.delivery-t {
				font-size: 14px;
				font-weight: 600;
				margin-bottom: 5px;
			                    }
								.zone-ignore-label {
    margin-right: 0px; 
	
}
				}
			}
			.delivery-detail-row{
			    display: flex;
    align-items: center;
    font-size: 13px;
			}
			
		}
    }
    .add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row .area-modal-detail-wrap span input{
    width: 70px;
    padding: 5px 0px;
}.add-deliver-modal-wrap .area-modal-body-wrap .area-modal-row .area-modal-detail-wrap .flex {
    margin-left: 15px;
}
    
	.delivery-area-page .accordian-c-t-wrp .accordian-c-t-d-wrap span {
    text-align: center;
    font-size: 12px;
    float: left;
    margin-left: 20px;
    text-align: center;
}
	.delivery-area-page .accordian-c-t-wrp .accordian-c-t-d-wrap span i {
    margin-right: 0px;
    font-size: 14px;
    color: #03A9F4;
}

.add-deliver-modal-wrap .city-town-wrap {
    display: block;
    margin-bottom: 20px;
}
.add-deliver-modal-wrap .city-town-wrap .select-city {
    margin-right: 0px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.select-city .control-label {
    width: 70px;
}
.res-pul-left {
    float: left;
    margin-right: 5px;
}
.add-deliver-modal-wrap .modal-area-wrap{
    margin-top: 60px;
    clear: both;
    position: relative;
}
    .menu-page-wrap .categorie-check-box-wrap div{margin-right: 10px;}
    .menu-page-wrap .items-check-boxes-wrap .items-heading {
        margin-top: 5px;
    }
    .items-heading{
        .hide-in-responsive{display: none;}
    }
    .media-wraper .res-logo-wrap, .media-wraper .res-search-photo-wrap, .media-wraper .res-menu-photo-wrap {
        margin-bottom: 20px;
    }

    .topping-list-wrap .topping-list-row .topping-list-inner-row{
        padding: 15px 70px 15px 15px;
    }
    .add-single-item-wrap .add-item-rd-row {
        margin-left: 0px;
    }
    .searchCustomModifications{
        width: 100% !important;
        margin: 0px !important;
    }
    .titleSearchDiv{
        flex-wrap:wrap;
        .card-title{
            margin-bottom: 15px;
        
        }
    }
    .wrimagecard{
        min-width: 160px;
        .textHeading{
            font-size: 15px !important;
        }
    }

    .enterprise-status-bar {
        div {
            display: block;
        }
    }

    #generalSettingsDv {
        .col-md-6, .col-sm-12, .col-xs-12 {
            padding: 0px 0px;
        }

        .generalSettingsChecks {
            padding: 0px 0px 20px 50px;
        }
    }

    #orderWrapper {
        .order-type-dropdown {
            position: relative;
            margin-bottom: 10px;
        }
    }

    .free-charges-input {
        width: 100%;
        margin: 10px 0px 30px 0px;
    }

    #generalSettingsDv .generalSettingsChecks {
        padding: 0px 0px 20px 0px;
    }

    .customtab2 {

        .hidden-xs-down {
            display: none;
        }
    }

    // .menu-page-wrap {
    //     margin-top: 90px;
    // }

    .menu-alert-wrap {
        padding: 6px;

        .left-alert {
            font-size: 14px;
            padding-right: 15px;

            span {
                word-break: break-word;
            }

            i {
                font-size: 18px;
                margin-right: 5px;
            }
        }

        .right-alert {
            .btn {
                font-size: 12px;
            }
        }
    }

    .modal-media-library-wrap .photo-library-wrap .photo-label {
        width: 120px;
    }

    .menu-page-wrap {
        .menu-right-penal {
            .add-option-field-wrap {
                display: table;
                padding: 0px 0px;
                margin-bottom: 15px;
                background: #f2f2f2;
                padding: 10px !important;
                border: 1px solid #d2d2d2;

                div {
                    width: 100%;
                }

                .add-option-save-btn {
                    text-align: center;
                    justify-content: center;
                    float: right;
                    width: 46%;
                    margin-bottom: 15px !important;
                    margin: 0px 2% !important;
                }
            }
        }
    }



    .menu-page-wrap .menu-right-penal .item-row-wrap .item-name {
        font-size: 16px;
    }

    .menu-page-wrap .menu-right-penal .item-row-wrap {
        font-size: 14px;
        align-items: center;
    }

    .item-option-detail-wrap .item-option-detail-inner-wrap .item-option-name-price span {
        padding-right: 10px;
    }

    .item-option-detail-wrap .item-option-detail-inner-wrap .item-option-topping-extras span {
        padding-right: 10px;
    }

    .menu-right-penal i {
        font-size: 18px;
    }

    .border-item-image .menu-item-image i{  font-size: 18px;}
    .menu-page-wrap .add-cat-btn i {
        font-size: 14px !important;
    }

    .item-row-wrap .menu-right-list-buttons {
        float: right;
        margin-top: 10px;
    }

    .review-box-wrap {
        margin: -8px;

        img {
            width: 45px;
        }

        .review-inner-admin-wrap, .review-box-detail-wrap {
            padding-left: 60px;
        }
    }

    .restaurant-admin-links-detail {
        text-align: center;
        margin-bottom: 20px;

        a {
            margin-top: 10px;
            display: inline-block;
            min-width: 130px;
        }
    }

    .restaurant-admin-detail {
        position: relative;
        width: 100%;
        float: left;
        text-align: center;

        .res-admin-img {
            border-radius: 50%;
            width: 80px;
            position: relative;
            border: 2px solid #d2d2d2;
            display: inline-block;
        }

        .restaurant-admin-inner-detail {
            padding-left: 0px;
            padding-top: 10px;
            width: 100%;
            text-align: left;

            h2 {
                text-align: center;
                font-size: 22px;
            }
        }
    }

    .fixed-delivery {
        background: #fff;
        padding: 15px;
        position: fixed !important;
        width: 100%;
        top: 60px;
        left: 0px;
    z-index: 1;
    animation:slide-down 0.7s;opacity:1;
        i{    position: absolute;
            top: 26px !important;
    left: 30px !important;}
    }
    .fixed-delivery + .area-modal-body-wrap{padding-top: 32px;}
    @keyframes slide-down {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        } 
        100% {
            opacity: 0.9;
            transform: translateY(0);
        } 
    }


    // .republish-btn{
    //     width: 145px !important;
    //     margin-right: 6px;
    // }
    // .republish-btn span + span {
    //     font-size: 12px;
    // }
    // .headerInfoDiv .btn-success{
    //     width:145px !important;
    // }
    // .headerInfoDiv .btn-success .statusSpan{
    //     font-size: 12px;
    //     line-height: 15px;
    // }
    .headerMain.shoply-header .m-r-10{
    margin-right: 5px;
    }
    .common-theme-wrap .theme-btn{
        margin-top: 20px;
    }
}

@media (max-width:400px){
    .text-change-common-res .modal-title{
        font-size: 16px;
    }
    .forgot-note {
        padding: 8px;
        font-size: 12px;
        width: 100%;
    }
    .forgotlogotext {
        font-size: 12px;
        margin-bottom: 20px;
    }
  
    .space-visible {
        margin-top: 133px!important;
    } 
    .wrimagecard{
        .textHeading{
            font-size: 14px !important;
        }
    }


    // .republish-btn{
    //     width: 125px !important;
    //     margin-right: 6px;
    // }
}
@media (max-width:380px) {
    .flex-column.flex-md-row.d-flex.align-items-md-center.mr-2 .react-datepicker-popper {
        z-index: 1;
        margin-left: -100px;
    }
    .progres-bar-wrap{
        width: 100%;
        margin-bottom: 0px;
       }
       .new-table-set .dataTables_filter {
           margin-top: 12px;
           max-width: 100%!important;
       }
       .count-down-wrap {
        padding: 2px 10px 3px 15px;
        border-radius: 4px;
        position: relative;
        width: 170px;
    }
}

@media (max-width:360px) {
  
    .react-datepicker__input-container {
        .form-control {
  
            font-size: 13px;
            padding: 7px 0px 7px 7px;
        }
    }
    .topping-l-wrap{
        .card-title{
            font-size: 18px;
        }
        .btn , .add-cat-btn{
            font-size: 13px!important;
        }
    }
    .notifi-main-wrap .btn-primary-plus{
        height: auto;
    }
    .headerInfoDiv{
        .display-flex-imp{
            i{margin: 0px;}
        }
.statusSpan{
    display: none;
}
    }
    .forgotlogotext {
        font-size: 12px;
        margin-bottom: 20px;
    }
    #orderWrapper .common-label-color {
        color: #777;
        font-size: 12px;
    }
    .pre-t-label {
        font-size: 12px;
    }
    
    #orderWrapper .status-type-label{
        font-size: 12px;
    }
    /*photo change modal css responsive*/
    .modal-media-library-wrap {
        .select-media-name, .search-photo-wrap {
            max-width: 100%;
            width: 100%;
            display: block;
            margin-bottom: 15px;

            .control-label {
                width: 100px;
                margin-bottom: 5px;
            }
        }
    }

    .time-input{
        font-size:10px;
    }

    // .republish-btn{
    //     width: 105px !important;
       
    // }
}

@media (max-width:350px) {
    .time-picker-main-wraper {
        .deliveryFirstDV{
            padding-left:0px;
            padding-right:0px;
        }
    }

}

.sortableHelper {
    z-index: 9999;
 }
.cat-back-btn {
    padding: 10px;
    margin-left: -15px;
}